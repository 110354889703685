import { BannerData } from '@innedit/innedit';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import CMSView from '../../../../../../../components/View';
import Form from '../../../../../../../containers/Espace/Form';
import params from '../../../../../../../params/banniere.json';
import TemplateEspace from '../../../../../../../templates/espace';
import requireEspace, {
  EspaceProps,
} from '../../../../../../../utils/requireEspace';
import { UserProps } from '../../../../../../../utils/requireUser';

const PageBannersUpdate: FC<PageProps & EspaceProps & UserProps> = props => {
  const {
    espace,
    user,
    params: { bannerId, channelId, espaceId },
  } = props;

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        <Form
          {...props}
          docId={bannerId}
          itemPathnamePrefix={`/espaces/${espaceId}/canaux/${channelId}/banners/`}
          model={new BannerData({ channelId, espaceId, params })}
          name="banner"
          type="update"
        />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(PageBannersUpdate);
